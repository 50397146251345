* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f55a5a;
  color: #fff;
  height: 60px;
}

.globe {
  height: 2.8rem;
  margin-right: 4px;
}

h2 {
  font-size: 2rem;
}

/* Card  */
.card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-cnt {
  margin: 2rem 2rem;
}

.travel-img {
  width: 600px;
}

.text-cnt {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 300px;
}

.pin {
  height: 1rem;
  margin-right: 4px;
}

h4 {
  margin: 1rem 0;
  letter-spacing: 2px;
  font-size: 1.4rem;
}

a {
  font-size: 1.4rem;
  margin-left: 6px;
  letter-spacing: 0;
  color: #918e9b;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  margin: 1rem 0;
  font-size: 2.5rem;
  color: #f55a5a;
  letter-spacing: 2px;
}

p {
  font-size: 1.3rem;
  letter-spacing: 2px;
  word-spacing: 2px;
}

@media (max-width: 960px) {
  .card {
    display: flex;
    flex-direction: column;
  }

  .travel-img {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .globe {
    height: 2.2rem;
    margin-right: 4px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .travel-img {
    width: 500px;
  }
  h1 {
    font-size: 2.2rem;
  }

  p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .globe {
    height: 2rem;
    margin-right: 4px;
  }
  h2 {
    font-size: 1.2rem;
  }
  .pin {
    height: 1rem;
    margin-right: 4px;
  }
  a {
    font-size: 1.2rem;
  }

  h4 {
    margin: 1rem 0;
    letter-spacing: 2px;
    font-size: 1.3em;
  }

  .travel-img {
    width: 300px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 0.9rem;
  }

  .text-cnt {
    padding: 2rem;
  }
}
